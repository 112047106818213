/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Submission } from '../models/Submission';
import type { Survey } from '../models/Survey';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SurveyService {
    /**
     * Get Survey
     * @returns Survey Successful Response
     * @throws ApiError
     */
    public static getSurveySurveyGet(): CancelablePromise<Survey> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/survey/',
        });
    }
    /**
     * Submit Survey
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static submitSurveySurveyPost(
        requestBody: Submission,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/survey/',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }
}
