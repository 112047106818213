import React, {CSSProperties, FunctionComponent, ReactElement, useEffect, useState} from 'react';

import {Survey, SurveyService} from "../api";
import SurveyComponent from "./Survey";

const styles: { [key: string]: CSSProperties } = {
    dreamReport: {
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
        width: "min(400px, 90%)",
        textAlign: "center",
    },
    continueButton: {
        marginTop: "5px",
    },
};

const DreamReport: FunctionComponent = () => {
    const [survey, setSurvey] = useState<Survey | undefined>(undefined);
    const [isError, setIsError] = useState<boolean>(false);
    const [inProgress, setInProgress] = useState<boolean>(false);
    const [isDone, setIsDone] = useState<boolean>(false);

    useEffect(() => {
        if (survey !== undefined) {
            return;
        }

        const cachedSurveyStr: string | null = localStorage.getItem('cachedSurvey');
        if (cachedSurveyStr) {
            const cachedSurvey: { date: number, survey: Survey } = JSON.parse(cachedSurveyStr);
            if (cachedSurvey && cachedSurvey.date >= Date.now() - 1000 * 60 * 30) {
                setSurvey(cachedSurvey.survey);
                localStorage.setItem('cachedSurvey', JSON.stringify({
                    date: Date.now(),
                    survey: cachedSurvey.survey,
                }));
                return;
            }
        }

        SurveyService.getSurveySurveyGet()
            .then((result: Survey) => {
                setSurvey(result);
                localStorage.setItem('cachedSurvey', JSON.stringify({
                    date: Date.now(),
                    survey: result,
                }));
            })
            .catch((err) => {
                console.error(err);
                setIsError(true);
            });

    }, [survey]);

    let currentElement: ReactElement;
    if (isDone) {
        currentElement = <p>Thank you.</p>;
    } else if (inProgress && survey !== undefined) {
        currentElement = <SurveyComponent
            survey={survey}
            onComplete={() => {
                localStorage.removeItem('cachedSurvey');
                setIsDone(true);
            }}
            onError={() => setIsError(true)}
        />;
    } else if (isError) {
        currentElement = <p>Do NOT talk about your dreams.</p>;
    } else {
        currentElement = <div>
            <p>Your dreams are powerful and nigh-omnipotent.</p>
            <p>You have the power to influence global events.</p>
            <p>We can act with your help.</p>
            <p>Please report your dream here.</p>
            <button
                style={styles.continueButton}
                disabled={survey === undefined}
                onClick={(e) => {
                    e.preventDefault();
                    setInProgress(true);
                }}
            >
                Continue
            </button>
        </div>;
    }

    return (
        <div style={styles.dreamReport}>
            {currentElement}
        </div>
    );
};

export default DreamReport;
