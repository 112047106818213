import {Answer, MultiChoiceQuestion, NumberQuestion, Submission, TextQuestion} from "../api";

export type SubmissionInProgress = Submission & {all_valid: boolean[]};

export const updateSubmission = (prev: SubmissionInProgress, index: number, newAnswer: any): SubmissionInProgress => {
    const {answers, all_valid, ...restOfOldSubmission} = prev;
    const {answer, ...restOfOldAnswer} = answers[index];
    const newAnswerObject: Answer = {
        ...restOfOldAnswer,
        answer: newAnswer,
    }
    return {
        ...restOfOldSubmission,
        answers: [
            ...answers.slice(0, index),
            newAnswerObject,
            ...answers.slice(index + 1),
        ],
        all_valid: [
            ...all_valid.slice(0, index),
            isValid(newAnswerObject),
            ...all_valid.slice(index + 1),
        ],
    };
}

export const isValid = (answer: Answer): boolean => {
    switch (answer.question.type) {
        case "multi_choice":
            return isValidMultiChoice(answer);
        case "number":
            return isValidNumber(answer);
        case "text":
            return isValidText(answer);
    }
}

const isValidMultiChoice = (answer: Answer): boolean => {
    let _answer: string | string[] | undefined;
    let question: MultiChoiceQuestion = answer.question as MultiChoiceQuestion;
    let answer_required: boolean = question.answer_required === undefined || question.answer_required;

    if (answer.answer === undefined) {
        return !answer_required;
    } else if (question.allow_multiple_answers) {
        _answer = answer.answer as Array<string>;
        if (_answer.length === 0) {
            _answer = undefined;
        }
    } else {
        _answer = answer.answer as string;
        if (_answer.length === 0) {
            _answer = undefined;
        }
    }

    if (_answer === undefined) {
        return !answer_required;
    }

    return true;
}

const isValidNumber = (answer: Answer): boolean => {
    let _answer: number | undefined;
    let question: NumberQuestion = answer.question as NumberQuestion;
    let answer_required: boolean = question.answer_required === undefined || question.answer_required;

    if (answer.answer === undefined) {
        return !answer_required;
    } else {
        _answer = answer.answer as number;
    }

    if (question.min_bound || question.min_bound === 0) {
        if (_answer < question.min_bound) {
            return false;
        }
    }

    if (question.max_bound || question.max_bound === 0) {
        if (_answer > question.max_bound) {
            return false;
        }
    }

    return true;
}

const isValidText = (answer: Answer): boolean => {
    let _answer: string | undefined;
    let question: TextQuestion = answer.question as TextQuestion;
    let answer_required: boolean = question.answer_required === undefined || question.answer_required;

    if (answer.answer === undefined) {
        return !answer_required;
    } else {
        _answer = answer.answer as string;
    }

    if (question.regex_pattern) {
        const regExp = new RegExp(question.regex_pattern);
        if (!regExp.test(_answer)) {
            return false;
        }
    }

    if (question.min_length || question.min_length === 0) {
        if (_answer.length < question.min_length) {
            return false;
        }
    }

    if (question.max_length || question.max_length === 0) {
        if (_answer.length > question.max_length) {
            return false;
        }
    }

    return true;
}