import {CSSProperties, FunctionComponent} from "react";

import {NumberQuestion} from "../api";

const styles: {[key: string]: CSSProperties} = {
    number: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
    },
};

export type NumberQuestionProps = {
    index: number;
    question: NumberQuestion;
    answer: number | undefined;
    valid: boolean;
    updateAnswer: (answer: any) => void;
}

const NumberQuestionComponent: FunctionComponent<NumberQuestionProps> = (props) => {
    const convertAnswer = (strAnswer: string): number | undefined => {
        const numAnswer = parseFloat(strAnswer);
        if (isNaN(numAnswer)) {
            return undefined;
        }
        if (props.question.input_type === "int" || props.question.input_type === undefined || props.question.input_type === null) {
            return Math.round(numAnswer);
        }
        return numAnswer;
    }

    return (
        <div style={styles.number}>
            <label htmlFor={`number-${props.index}`}>{props.question.prompt.base}</label>
            <input
                type={"number"}
                id={`number-${props.index}`}
                value={props.answer}
                style={props.valid ? {} : {border: "1px solid red"}}
                onChange={(event) => {
                    props.updateAnswer(convertAnswer(event.target.value));
                }}
            />
        </div>
    );
}

export default NumberQuestionComponent;