import {FunctionComponent} from "react";

import MultiChoiceQuestionComponent, {MultiChoiceQuestionProps} from "./MultiChoiceQuestion";
import NumberQuestionComponent, {NumberQuestionProps} from "./NumberQuestion";
import TextQuestionComponent, {TextQuestionProps} from "./TextQuestion";
import {MultiChoiceQuestion, NumberQuestion, TextQuestion} from "../api";

export type QuestionProps = {
    index: number;
    question: MultiChoiceQuestion | NumberQuestion | TextQuestion;
    answer: any;
    valid: boolean;
    updateAnswer: (answer: any) => void;
};

const QuestionComponent: FunctionComponent<QuestionProps> = (props) => {
    switch (props.question.type) {
        case "multi_choice":
            return MultiChoiceQuestionComponent(props as MultiChoiceQuestionProps);
        case "number":
            return NumberQuestionComponent(props as NumberQuestionProps);
        case "text":
            return TextQuestionComponent(props as TextQuestionProps);
        default:
            throw new Error("Invalid question");
    }
};

export default QuestionComponent;