import {CSSProperties, FunctionComponent, ReactElement} from "react";

import {TextQuestion} from "../api";

const styles: {[key: string]: CSSProperties} = {
    textQuestion: {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        gap: "10px",
    },
    prompt: {
        textAlign: "center",
        fontWeight: "bold",
    },
};

export type TextQuestionProps = {
    index: number;
    question: TextQuestion;
    answer: string | undefined;
    valid: boolean;
    updateAnswer: (answer: any) => void;
}

const convertAnswer = (strAnswer: string): string | undefined => {
    return strAnswer ? strAnswer : undefined;
};

const TextQuestionComponent: FunctionComponent<TextQuestionProps> = (props) => {
    const textInputStyle: CSSProperties = {};
    if (!props.valid)
        textInputStyle.border = "1px solid red";

    let textInput: ReactElement;
    if (props.question.large_input) {
        textInputStyle.width = "99%";
        textInputStyle.height = "min(50vh, 300px)";
        textInput = <textarea
            id={`text-${props.index}`}
            value={props.answer}
            style={textInputStyle}
            onChange={(event) => {
                props.updateAnswer(convertAnswer(event.target.value));
            }}
        />;
    } else {
        textInput = <input
            id={`text-${props.index}`}
            value={props.answer}
            type={"text"}
            style={textInputStyle}
            onChange={(event) => {
                props.updateAnswer(convertAnswer(event.target.value));
            }}
        />;
    }

    return (
        <div style={styles.textQuestion}>
            <label
                htmlFor={`text-${props.index}`}
                style={styles.prompt}
            >
                {props.question.prompt.base}
            </label>
            {textInput}
        </div>
    );
};

export default TextQuestionComponent;