import React, {CSSProperties, FunctionComponent, useState} from "react";

import {isValid, SubmissionInProgress, updateSubmission} from "./utils";
import QuestionComponent from "./Question";
import {Answer, Submission, Survey, SurveyService} from "../api";

const styles: { [key: string]: CSSProperties } = {
    questionCount: {
        paddingBottom: "20px",
    },
    buttonContainer: {
        marginTop: "20px",
        display: "flex",
        columnGap: "20px",
    },
    button: {
        flex: "auto",
    },
}

const audio = new Audio("/submit.mp3");
audio.volume = 0.4;

const getInitialState = (survey: Survey): SubmissionInProgress => {
    const survey_id: string = survey._id || "";
    const answers: Answer[] = survey.questions.map((question): Answer => {
        return {
            answer: question.type === "multi_choice" && question.selection_type === "select" ? question.choices[0].base : undefined,
            question: question
        };
    });
    return {
        survey_id: survey_id,
        answers: answers,
        all_valid: answers.map((answer: Answer) => isValid(answer)),
    };
}

type SurveyProps = { survey: Survey, onComplete: () => void, onError: () => void };

const SurveyComponent: FunctionComponent<SurveyProps> = (props: SurveyProps) => {
    const {survey, onComplete, onError} = props;
    const [submission, setSubmission] = useState<SubmissionInProgress>(getInitialState(survey));
    const [currentIndex, setCurrentIndex] = useState(0);
    const [submitting, setSubmitting] = useState(false);

    const canGoBack: boolean = currentIndex > 0;
    const canGoForward: boolean = currentIndex < submission.answers.length - 1 && submission.all_valid.slice(0, currentIndex + 1).every(v => v);
    const canSubmit: boolean = submission.all_valid.every(v => v) && !submitting;

    const setNewAnswer = (index: number, newAnswer: any) => {
        setSubmission(updateSubmission(submission, index, newAnswer));
    };

    const submit = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        if (submitting)
            return;
        setSubmitting(true);

        const {all_valid, ...sub} = submission;
        SurveyService.submitSurveySurveyPost(sub as Submission)
            .then(() => {
                audio.play().catch((err) => console.error(err));
                onComplete();
            })
            .catch((err) => {
                console.error(err);
                onError();
            }).finally(() => setSubmitting(false));
    };

    return (
        <form>
            <div style={styles.questionCount}>
                Question {currentIndex + 1} of {survey.questions.length}
            </div>
            <div key={survey.questions[currentIndex]._id}>
                <QuestionComponent
                    index={currentIndex}
                    question={survey.questions[currentIndex]}
                    answer={submission.answers[currentIndex].answer}
                    valid={submission.all_valid[currentIndex]}
                    updateAnswer={(answer: any) => setNewAnswer(currentIndex, answer)}
                />
            </div>
            <div style={styles.buttonContainer}>
                <button
                    style={styles.button}
                    disabled={!canGoBack}
                    onClick={(e) => {
                        e.preventDefault();
                        setCurrentIndex(currentIndex - 1);
                    }}
                >
                    Previous
                </button>
                <button
                    style={styles.button} disabled={!canSubmit}
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => submit(e)}
                >
                    Submit
                </button>
                <button
                    style={styles.button}
                    disabled={!canGoForward}
                    onClick={(e) => {
                        e.preventDefault();
                        setCurrentIndex(currentIndex + 1);
                    }}
                >
                    Next
                </button>
            </div>
        </form>
    );
};

export default SurveyComponent;