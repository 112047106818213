import {CSSProperties, FunctionComponent} from "react";

import {MultiChoiceQuestion, ParameterizedString} from "../api";

const selectStyles: { [key: string]: CSSProperties } = {
    dropDown: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        gap: "10px",
    },
};

const checkBoxStyles: { [key: string]: CSSProperties } = {
    question: {
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
        gap: "10px",
    },
    prompt: {
        textAlign: "center",
        fontWeight: "bold",
    },
    option: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
    },
};

export type MultiChoiceQuestionProps = {
    index: number;
    question: MultiChoiceQuestion;
    answer: string | string[] | undefined;
    valid: boolean;
    updateAnswer: (answer: any) => void;
};

const MultiChoiceQuestionComponent: FunctionComponent<MultiChoiceQuestionProps> = (props) => {
    const updateSingleAnswer = (selection: string) => {
        if (props.answer === selection) {
            props.updateAnswer(undefined);
        } else {
            props.updateAnswer(selection);
        }
    };

    const updateMultiAnswer = (selection: string) => {
        const prevAnswer: string[] | undefined = props.answer ? props.answer as string[] : undefined;

        if (prevAnswer === undefined) {
            props.updateAnswer([selection]);
            return;
        }

        const position: number = prevAnswer.indexOf(selection);
        if (position < 0) {
            props.updateAnswer(prevAnswer.concat(selection));
        } else {
            if (prevAnswer.length === 1) {
                props.updateAnswer(undefined);
            } else {
                props.updateAnswer([
                    ...prevAnswer.slice(0, position),
                    ...prevAnswer.slice(position + 1),
                ]);
            }
        }
    };

    const updateAnswer = (selection: string) => {
        if (props.question.allow_multiple_answers) {
            updateMultiAnswer(selection);
        } else {
            updateSingleAnswer(selection);
        }
    };

    switch (props.question.selection_type) {
        case "select":
            return (
                <div style={selectStyles.dropDown}>
                    <label htmlFor={`drop-down-${props.index}`}>{props.question.prompt.base}</label>
                    <select
                        id={`drop-down-${props.index}`}
                        value={props.question.allow_multiple_answers ? props.answer as readonly string[] : props.answer}
                        multiple={props.question.allow_multiple_answers}
                        onChange={(e) => updateAnswer(e.target.value)}
                    >
                        {props.question.choices.map((choice: ParameterizedString, index: number) =>
                            <option
                                key={index}
                                value={choice.base}
                            >
                                {choice.base}
                            </option>
                        )}
                    </select>
                </div>
            );
        case "checkbox":
        case "radio":
        case undefined:
        case null:
        default:
            return (
                <div style={checkBoxStyles.question}>
                    <div style={checkBoxStyles.prompt}>{props.question.prompt.base}</div>
                    {props.question.prompt_images &&
                        <img src={props.question.prompt_images[0]} alt={props.question.prompt.base}/>
                    }
                    {props.question.choices.map((choice: ParameterizedString, index: number) => {
                        const checked = props.answer === choice.base ||
                            (Array.isArray(props.answer) && props.answer.indexOf(choice.base) >= 0);
                        return (
                            <div style={checkBoxStyles.option} key={index}>
                                <input
                                    type={"checkbox"}
                                    id={`checkbox-${props.index}-${index}`}
                                    checked={checked}
                                    onChange={() => updateAnswer(props.question.choices[index].base)}
                                />
                                <label htmlFor={`checkbox-${props.index}-${index}`}>{choice.base}</label>
                            </div>
                        );
                    })}
                </div>
            );
    }
}

export default MultiChoiceQuestionComponent;